<template>
	<!--三要素认证 -->
    <div class="auth-res">
		<div class="main">
			<div class="auth-error">
				<div class="img">
					<img src="@/assets/yq/auth-error-03.png">
				</div>
				<div class="status-text">
					<span>{{statusText}}</span>
				</div>
				<div class="status-desc">
					{{statusDesc}}
				</div>
				<div class="btn">
					<!-- <button class="again">重新认证</button> -->
					<div @click="authAgain">
						<zlButton text="重新认证"  />
					</div>
					<button class="replace" @click="authReplace" v-if="replaceShow">{{buttonText}}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import zlButton from "@/components/zlButton.vue";
import { getAuthInfo,setAuthInfo } from "@/utils/auth";
import { getCheckStatus } from "@/api/user";
export default {
	components: {
		zlButton,
	},
	data() {
		return {
			title:"身份认证",
			backVisible:false,
			authRes:true,
			buttonText:"",
			authType:'', //认证类型 1是三要素认证，2是人脸识别
			firstLoad:true,
			statusText:"",
			statusDesc:"",
			replaceShow:true,
		}
	},
	methods: {
		authAgain(){
			let url= "";
			if(this.authType == 1){
				url = "/threeAuth";
			}else{
				url = "/ocrIdAuth";
			}
			this.$router.replace({
                path: url
            })
			return;
		},
		authReplace(){
			let url= "";
			if(this.authType == 1){
				url = "/ocrIdAuth";
			}else{
				url = "/threeAuth";
			}
			this.$router.replace({
                path: url
            })
			return;
		}
	},
	created(){
		let {authType,errMessage} = this.$route.query;
        this.authType = authType;
		// let authType = 2;
		if(authType == 1){
			this.statusText = "身份认证失败，请重新认证";
			this.statusDesc = "请核对姓名/身份证号码和手机号码是否存在绑定关系手机号营业厅实名身份证必须与上传身份证一致";
			this.buttonText = "更换人脸识别认证"
		}else{
			this.statusText = "身份认证失败";
			this.statusDesc = "人脸与身份证照片不是同一人";
			this.buttonText = "更换三要素认证"
		}
		if(errMessage){
			this.statusDesc = errMessage;
		}
		getCheckStatus().then((res) => {
			let { status, data } = res;
			if(status){
				if( data.checkPass ){
					this.replaceShow = false;
				}
				setAuthInfo(JSON.stringify(data));
			}
		})
		
	}
};
</script>

<style scoped>
div{
	box-sizing: border-box;
}
.auth-res .main{
	padding-top: 130px;
}
.auth-res .img{
	text-align: center;
}
.auth-res .img img{
	width: 177px;
	height: 103px;
}
.auth-res .auth-error .status-text{
	margin-top: 42px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.auth-res .auth-error .status-text img{
	width: 19px;
	height: 19px;
}
.auth-res .auth-error .status-text span{
	margin-left: 5.5px;
	font-size: 18px;
	font-weight: 600;
}
.auth-res .auth-error .status-desc{
	margin: 0 auto;
	margin-top: 19px;
	text-align: center;
	width: 328px;
	font-size: 14px;
	font-weight: 500;
	color: #B0B0B0;
}
.auth-res .auth-error .btn{
	width: 292px;
	margin:64.5px auto;
}
.auth-res .auth-error .btn button{
	width: 292px;
	height: 48px;
	border-radius: 24px;
}

.auth-res .auth-error .btn .replace{
	background: #fff;
	color: #0471FD;
	border:1px solid #0471FD;
	margin-top:20px ;

}
.auth-success .status-text{
	margin-top: 71px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.auth-success .status-text img{
	width: 19px;
	height: 19px;
}
.auth-success .status-text span{
	margin-left: 5.5px;
	font-size: 18px;
	font-weight: 600;
}
.auth-success .status-text-p{
	margin-top: 10px;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	margin-left: 12px;
}
.auth-success .btn{
	width: 292px;
	margin:37.5px auto;
	margin-bottom: 0;
}
</style>
