<template>
    <div class="zl-button">
        <button class="again" 
            :style="{height:height+'px',background:background,
            'border-radius':(height/2 )+'px',color:color,
            'border':border}"
        >
            {{text}}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required:true
        },
        background:{
            type: String,
            default: '#4D62B8'
        },
        color:{
            type: String,
            default: '#fff'
        },
        height:{
            type: Number,
            default: 48
        },
        border:{
            type: String,
            default: 'none'
        }
    },
};
</script>

<style scoped>
.zl-button{
	width: 100%;
	height: 48px;
	border-radius: 24px;
    background: #fff;
	color: #0471FD;
    text-align: center;
}
.zl-button button{
    width: 100%;
    border: none;
}
.zl-button button:active{
	opacity: 0.8;
}

</style>